<template>
  <div>
    <div
      class="d-flex justify-center"
      :class="[isMobile ? 'align-center' : 'align-end']"
      style="height: 88px; position: absolute; top: 0%"
      :style="{
        left: '20%',
        width: '60%',
        fontSize: `${displaySize(35, 0.12)}px`,
      }"
    >
      <span class="mTitleGame text-truncate"> Solution</span>
    </div>
    <app-bar currentPage="solution"></app-bar>
    <div
      class="d-flex flex-column align-center"
      :class="[isMobile ? 'mt-3' : 'mt-16']"
      :style="{ height: `${windowHeight - 90}px` }"
    >
      <iframe
        :src="solution.html"
        :width="isMobile ? '95%' : '50%'"
        :height="videoHeight"
        frameborder="0"
        allow="autoplay; fullscreen; picture-in-picture;"
        allowfullscreen
        title="The Letter"
      ></iframe>
    </div>
  </div>
</template>

<script>
// import LoadingCircle from "@/components/LoadingCircle.vue";
import AppBar from "@/components/AppBar.vue";

export default {
  props: ["id", "answer"],
  components: { AppBar },
  data() {
    return {
      solution: {},
    };
  },
  computed: {
    videoHeight() {
      if (this.isMobile) return "270px";
      if (this.windowHeight - 90 < 480)
        return `${(this.windowHeight - 90) * 0.6}px`;
      return "480px";
    },
  },
  async created() {
    this.$store.commit("currentPage", { page: "solution" });
    if (this.answer) this.solution = this.answer;
    else {
      this.solution = await this.$store.dispatch("game/fetchAnswer", {
        id: this.id,
      });
      if (this.solution == "No Paid Solution!") {
        this.$router.push({ name: "main" });
      }
    }
  },
};
</script>